const PageFooter = () => {
  const today = new Date()
  const year = today.getFullYear()
  return (
    <footer className="text-center">
      <span>{year} Académie de Bordeaux - Tous droits réservés</span>
      &nbsp;|&nbsp;
      <span>
        <a href="https://amerana.region-academique-nouvelle-aquitaine.fr/">
          Signaler un problème technique
        </a>
      </span>
    </footer>
  )
}
export default PageFooter
