import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import PageFooter from './components/PageFooter'
import { Container } from 'react-bootstrap'
import PageHeader from './components/PageHeader'
import AppRouter from './components/AppRouter'

function App() {
  return (
    <Container>
      <PageHeader />
        <AppRouter />
      <PageFooter />
    </Container>
  )
}

export default App
