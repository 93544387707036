import { Button, Spinner } from 'react-bootstrap'
import { useEffect, useRef, useState } from 'react'
import { Arenas } from '../config/Config'
import axios from 'axios'

const Home = () => {
  const dataFetchedRef = useRef(false)
  const [myArenas, setMyArenas] = useState(Arenas)

  useEffect(() => {
    const automaticRedirect = () => {
      for (let i = 0; i < myArenas.length; i++) {
        for (let j = 0; j < myArenas[i].portals.length; j++) {
          if (myArenas[i].portals[j].isAllowed) {
            window.location.replace(myArenas[i].portals[j].url)
            break
          }
        }
      }
    }

    const getArenas = async () => {
      if (dataFetchedRef.current) {
        return
      }
      dataFetchedRef.current = true

      for (let i = 0; i < myArenas.length; i++) {
        for (let j = 0; j < myArenas[i].portals.length; j++) {
          if (myArenas[i].portals[j].pingurl !== null) {
            let newArenas = [...myArenas]
            try {
              await axios.get(myArenas[i].portals[j].pingurl, { timeout: 2000 })
              newArenas[i].portals[j].isAllowed = true
            } catch (error) {
              console.log(error)
              newArenas[i].portals[j].isAllowed = false
            } finally {
              setMyArenas(newArenas)
            }
          }
        }
      }
      automaticRedirect()
    }
    getArenas()
  }, [myArenas])

  return (
    <div className="text-center">
      <Button disabled>
        <div className="row">
          <div className="col col-1 m-auto">
            <Spinner animation="border" size="" />
          </div>
          <div className="col-11">
            Recherche du portail en cours, <br />
            vous serez automatiquement redirigé.
          </div>
        </div>
      </Button>
    </div>
  )
}
export default Home
