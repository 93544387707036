import Marianne from '../assets/images/logo-ac-bordeaux.png'
import { Col, Row } from 'react-bootstrap'
const PageHeader = () => {
  return (
    <header>
      <Row>
        <Col>
          <img src={Marianne} alt="Logo académie de Bordeaux" />
        </Col>
        <Col className="m-auto">
          <h1>Accédez à vos applications</h1>
        </Col>
      </Row>
    </header>
  )
}
export default PageHeader
