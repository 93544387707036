export const Arenas = [
  {
    academy: 'bordeaux',
    portals: [
      {
        pingurl: 'https://gestion.in.ac-bordeaux.fr/ping-gestion/',
        //pingurl: 'https://int-arena.ac-bordeaux.fr/ping-gestion/',
        url: 'https://gestion.in.ac-bordeaux.fr',
        shortLabel: 'Gestion',
        description:
          'Accessible depuis le réseau du Rectorat, des DSDEN, des circonscriptions ou en VPN.',
        longLabel: 'Pour les personnels des services administratifs',
        isAllowed: false,
      },
      {
        pingurl: 'https://ginet.in.ac-bordeaux.fr/ping-ginet/',
        //pingurl: 'https://int-arena.ac-bordeaux.fr/ping-ginet/',
        url: 'https://ginet.in.ac-bordeaux.fr',
        shortLabel: 'Ginet',
        description:
          'Accessible depuis le réseau administratif des établissements scolaires publics.',
        longLabel: 'Pour les personnels administratifs en établissement public',
        isAllowed: false,
      },
      {
        pingurl: null,
        url: 'https://portailrh.ac-bordeaux.fr',
        shortLabel: 'Portailrh',
        description: "Accessible depuis n'importe quel emplacement.",
        longLabel: 'Pour tous les utilisateurs',
        isAllowed: true,
      },
    ],
  },
]
